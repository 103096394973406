<script>
import { ref, watch } from "vue";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import "flatpickr/dist/flatpickr.css";
import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net";
import "datatables.net-select";
import "datatables.net-responsive";

import useVuelidate from "@vuelidate/core";

import Layout from "../../../layouts/main.vue";
import appConfig from "../../../../app.config";
import PageHeader from "@/components/page-header";
import Axios from "@/config/axios";

import store from "../../../state/store";

DataTable.use(DataTablesCore);

const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export default {
  page: {
    title: "Creer un Produit",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  setup() {
    let files = ref([]);
    let dropzoneFile = ref("");
    const drop = (e) => {
      dropzoneFile.value = e.dataTransfer.files[0];
      files.value.push(dropzoneFile.value);
    };
    const selectedFile = () => {
      dropzoneFile.value = document.querySelector(".dropzoneFile").files[0];
      files.value.push(dropzoneFile.value);
    };
    watch(
      () => [...files.value],
      (currentValue) => {
        return currentValue;
      }
    );
    return {
      dropzoneFile,
      drop,
      selectedFile,
      v$: useVuelidate(),
      files,
    };
  },
  data() {
    return {
      title: "Creer un Produit",
      items: [
        {
          text: "Ecommerce",
          href: "/",
        },
        {
          text: "Creer un Produit",
          active: true,
        },
      ],
      categories: [],
      date1: null,
      datetimeConfig: {
        enableTime: true,
        dateFormat: "d.m.y",
        time_24hr: true,
      },
      loading: false,
      fields: {
        specifications: [{ property: "", value: "" }],
        specifications_details: [
          { price: 0, quantity: 0, weight: 0, image: null },
        ],
        name: "",
        categories: [],
        images: [],
        isNew: false,
        isHot: false,
        isOutOfStock: false,
        price: 0,
        sale_price: 0,
        stock: 0,
        short_description: "",
        is_published: [true],
      },
    };
  },
  computed: {
    columns: function () {
      if (this.fields)
        return this.cartesianProduct(
          this.restructureProperties(
            this.fields.specifications.slice(0, -1)
          ).map((item) => item.values)
        );
      else return [];
    },
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
  },

  beforeMount() {
    this.getcategories();
  },
  methods: {
    check_validation() {
      if (!this.fields.name.trim()) {
        alert("Veuillez renseigner le nom du produit");
        return false;
      }

      if (!this.fields.categories.length) {
        alert("Veuillez renseigner au moins une catégorie au produit");
        return false;
      }

      if (!this.fields.images.length) {
        alert("Veuillez renseigner au moins une image au produit");
        return false;
      }

      return true;
    },
    restructureProperties: (properties) => {
      if (properties) {
        let result = {};

        properties.forEach((item) => {
          let propertyName = item.property;
          let propertyValue = item.value;

          if (!result[propertyName]) {
            result[propertyName] = [];
          }

          result[propertyName].push(propertyValue);
        });

        return Object.keys(result).map((key) => ({
          property: key,
          values: result[key],
        }));
      } else return [[]];
    },
    cartesianProduct: (arr) => {
      //   console.log(Array.isArray(arr), typeof arr, arr);
      let res = [arr] ?? [[]];
      if (arr[0]?.length) {
        res = arr.reduce((a, b) =>
          a.flatMap((d) => b.map((e) => [d, e].flat()))
        );
      }
      const specificationsDetails = [];
      res.forEach(() => {
        specificationsDetails.push({
          price: 0,
          quantity: 0,
        });
      });
      return res;
    },
    uploadImage: async function (event, index) {
      const file = event.target.files[0];
      const base64 = await convertBase64(file);
      this.fields.specifications_details[index].image = base64;
      // console.log(this.fields);
    },
    handleSpecifications_details: function () {
      const tab = this.cartesianProduct(
        this.restructureProperties(this.fields.specifications.slice(0, -1)).map(
          (item) => item.values
        )
      );
      const specificationsDetails = tab.map((item, index) => {
        const details = this.fields.specifications_details[index];
        const constraints = this.restructureProperties(
          this.fields.specifications.slice(0, -1)
        ).map((it, ind) => {
          return {
            key: it.property,
            value: Array.isArray(item) ? item[ind] : item,
          };
        });
        return {
          constraints: constraints,
          detail: {
            price: details.price,
            quantity: details.quantity,
            weight: details.weight,
            productImage: details.image,
          },
        };
      });
      this.fields.specifications_details = specificationsDetails;
      this.fields.specifications = this.fields.specifications.slice(0, -1);
      console.log(this.fields);
    },
    async handleSubmit() {
      store.dispatch("setGlobalLoading", true);

      if (!this.check_validation()) {
        store.dispatch("setGlobalLoading", false);
        return false;
      }

      let i = 0;
      for (const image of this.fields.images) {
        const url = await this.$upload_file(image, this.fields.name);
        this.fields.images[i] = url;
        i++;
      }

      let j = 0;
      for (const detail of this.fields.specifications_details) {
        console.log(detail);
        if (detail.image) this.fields.specifications_details[j].image = await this.$upload_file(detail.image,this.fields.name);
        j++;
      }
      console.log(this.fields.images, this.fields.specifications_details);

      this.handleSpecifications_details();

      3
      Axios.post("/admin/products", {
        ...this.fields,
        is_published: this.fields.is_published[0],
        // specifications: this.fields.specifications_details,
      })
        .then(() => {
          alert("Le produit a bien été ajouté !");
          this.$router.push("/products");
        })
        .catch((er) => {
          console.log(er);
          alert("Une erreur est survenue lors de l'ajout du produit");
        })
        .finally(() => {
          store.dispatch("setGlobalLoading", false);
        });
    },
    getcategories() {
      Axios.get("/admin/product-categories")
        .then(({ data }) => {
          this.categories = data.data;
        })
        .catch((er) => {
          console.log(er);
        });
    },
    handleAddSpec() {
      this.fields.specifications = [
        ...this.fields.specifications,
        { property: "", value: "" },
      ];
      this.fields.specifications_details = [
        ...this.fields.specifications_details,
        { price: 0, quantity: 0, weight: 0, image: null },
      ];
    },
    handleRemoveSpec(index) {
      this.fields.specifications = this.fields.specifications.filter(
        (item, id) => id != index
      );

      this.fields.specifications_details =
        this.fields.specifications_details.filter((item, id) => id != index);
    },
    handleRemove(image) {
      this.fields.images.splice(this.fields.images.indexOf(image), 1);
    },

    async uploadImages(event) {
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];
        const base64 = await convertBase64(file);
        this.fields.images = [...this.fields.images, base64];
      }
    },
    async handleDrop(event) {
      for (const item of [...event.dataTransfer.items]) {
        const file = item.getAsFile();
        const base64 = await convertBase64(file);
        this.fields.images = [...this.fields.images, base64];
      }
    },
    allowDrop(ev) {
      ev.preventDefault();
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-8">
        <form @submit.prevent="handleSubmit">
          <div class="card">
            <div class="card-body">
              <div class="mb-3">
                <label class="form-label" for="product-title-input"
                  >Titre du Produit</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="product-title-input"
                  placeholder="Entrez le titre du produit"
                  v-model="fields.name"
                />
              </div>

              <div class="form-group mb-3">
                <label class="form-label" for="product-title-input"
                  >Catégorie du produits</label
                >

                <Multiselect
                  class="form-control"
                  mode="tags"
                  v-model="fields.categories"
                  :close-on-select="false"
                  :searchable="true"
                  :create-option="true"
                  :options="[
                    ...categories.map((category) => ({
                      value: category.id,
                      label: category.name,
                    })),
                  ]"
                />
              </div>

              <div class="row mb-3 align-items-center">
                <div class="col-6">
                  <label class="form-label" for="product-title-input"
                    >Quantité en stock</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    id="product-title-input"
                    placeholder="Stock"
                    v-model="fields.stock"
                  />
                </div>

                <div class="col-6">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="is_out_of_stock"
                      v-model="fields.isOutOfStock"
                    />
                    <label class="form-check-label" for="is_out_of_stock">
                      En rupture de stock ?
                    </label>
                  </div>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-6">
                  <label class="form-label" for="product-title-input"
                    >Prix du Produit</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    id="product-title-input"
                    placeholder="Entrez le prix du produit"
                    v-model="fields.price"
                  />
                </div>

                <div class="col-6">
                  <label class="form-label" for="product-title-input"
                    >Prix solde (Laisser vide si le produit n'est pas en
                    solde)</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    id="product-title-input"
                    placeholder="Entrez le prix promo"
                    v-model="fields.sale_price"
                  />
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-6">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="is_new"
                      v-model="fields.isNew"
                    />
                    <label class="form-check-label" for="is_new">
                      Nouveauté
                    </label>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="is_hot"
                      v-model="fields.isHot"
                    />
                    <label class="form-check-label" for="is_hot">
                      Etiquette (CHAUD)
                    </label>
                  </div>
                </div>
              </div>

              <div>
                <label>Description du produit</label>
                <textarea
                  class="form-control"
                  name="description"
                  id="description"
                  rows="10"
                  v-model="fields.short_description"
                ></textarea>
              </div>
            </div>
          </div>
          <!-- end card -->

          <div class="card">
            <div class="card-header">
              <h5 class="card-title mb-0">Galerie de produits</h5>
            </div>
            <div class="card-body">
              <div class="vstack gap-2">
                <div class="row align-items-center">
                  <div class="col-6">
                    <h5 class="fs-14 mb-1">Galerie du produits</h5>
                    <p class="text-muted">
                      Ajouter des images de la galerie de produits.
                    </p>
                  </div>

                  <div class="col-6 d-flex justify-content-end">
                    <label class="btn btn-primary" for="img-file-input">
                      + Ajouter des images
                    </label>
                    <input
                      id="img-file-input"
                      type="file"
                      class="img-file-input d-none"
                      multiple
                      @change="uploadImages"
                    />
                  </div>

                  <label
                    class="drop-zone"
                    for="img-file-input"
                    @drop.prevent="handleDrop"
                    @dragenter.prevent
                    @dragover.prevent
                  >
                    <div>
                      Cliquez pour ajouter ou des images ou glissez-déposez
                    </div>
                  </label>
                </div>
                <!-- <DropZone @drop.prevent="drop" @change="selectedFile" /> -->

                <div class="row">
                  <div
                    class="col-4 col-md-4"
                    style="position: relative"
                    v-for="image in fields.images"
                    :key="image"
                  >
                    <img
                      style="max-width: 95%"
                      class="w-100"
                      :src="image"
                      alt=""
                    />
                    <div
                      style="
                        position: absolute;
                        right: 5px;
                        top: -10px;
                        background: gray;
                        height: 20px;
                        width: 20px;
                        text-align: center;
                        vertical-align: middle;
                        border-radius: 50%;
                        color: white;
                        cursor: pointer;
                      "
                      @click="handleRemove(image)"
                    >
                      X
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end card -->

          <div class="card">
            <div class="card-header">
              <ul
                class="nav nav-tabs-custom card-header-tabs border-bottom-0"
                role="tablist"
              >
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    data-bs-toggle="tab"
                    href="#addproduct-general-info"
                    role="tab"
                  >
                    Spécifications du produit
                  </a>
                </li>
                <!-- <li class="nav-item">
                  <a class="nav-link" data-bs-toggle="tab" href="#addproduct-metadata" role="tab">
                    Meta Data
                  </a>
                </li> -->
              </ul>
            </div>
            <!-- end card header -->
            <div class="card-body">
              <div class="tab-content">
                <div
                  class="tab-pane active"
                  id="addproduct-general-info"
                  role="tabpanel"
                >
                  <div
                    class="row align-items-center mb-2"
                    v-for="(item, index) in fields.specifications"
                    :key="index"
                  >
                    <div class="col-lg-5">
                      <div class="mb-3">
                        <label class="form-label">Proprieté</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Entrez la proprieté"
                          v-model="item.property"
                        />
                      </div>
                    </div>
                    <!-- end col -->
                    <div class="col-lg-5">
                      <div class="mb-3">
                        <label class="form-label" for="product-price-input"
                          >Valeur</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="manufacturer-brand-input"
                          placeholder="Entrez la valeur"
                          v-model="item.value"
                        />
                      </div>
                    </div>
                    <div class="col-lg-2">
                      <button
                        @click="handleRemoveSpec(index)"
                        type="button"
                        class="btn btn-danger"
                      >
                        Effacer
                      </button>
                    </div>
                    <!-- end col -->
                  </div>
                  <!-- end row -->

                  <button
                    type="button"
                    class="btn btn-secondary mt-2"
                    style="float: right"
                    @click="handleAddSpec"
                  >
                    Ajouter
                  </button>
                </div>
                <!-- end tab-pane -->

                <div class="tab-pane" id="addproduct-metadata" role="tabpanel">
                  <div class="row">
                    <!-- <div class="col-lg-6">
                      <div class="mb-3">
                        <label class="form-label" for="meta-title-input">Meta title</label>
                        <input type="text" class="form-control" placeholder="Enter meta title" id="meta-title-input" />
                      </div>
                    </div> -->
                    <!-- end col -->

                    <!-- <div class="col-lg-6">
                      <div class="mb-3">
                        <label class="form-label" for="meta-keywords-input">Meta Keywords</label>
                        <input type="text" class="form-control" placeholder="Enter meta keywords"
                          id="meta-keywords-input" />
                      </div>
                    </div> -->
                    <!-- end col -->
                  </div>
                  <!-- end row -->

                  <!-- <div>
                    <label class="form-label" for="meta-description-input">Meta Description</label>
                    <textarea class="form-control" id="meta-description-input" placeholder="Enter meta description"
                      rows="3"></textarea>
                  </div> -->
                </div>
                <!-- end tab pane -->
              </div>
              <!-- end tab content -->
            </div>
            <!-- end card body -->
          </div>
          <!-- end card -->
          <div class="card">
            <div class="card-header">
              <ul
                class="nav nav-tabs-custom card-header-tabs border-bottom-0"
                role="tablist"
              >
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    data-bs-toggle="tab"
                    href="#addproduct-general-info"
                    role="tab"
                  >
                    Informations additionnel sur le produit
                  </a>
                </li>
                <!-- <li class="nav-item">
                  <a class="nav-link" data-bs-toggle="tab" href="#addproduct-metadata" role="tab">
                    Meta Data
                  </a>
                </li> -->
              </ul>
            </div>
            <!-- end card header -->
            <div class="card-body">
              <div class="tab-content">
                <div
                  class="tab-pane active"
                  id="addproduct-general-info"
                  role="tabpanel"
                >
                  <DataTable
                    class="display table align-middle table-nowrap mb-0"
                  >
                    <thead>
                      <tr>
                        <th
                          v-for="(item, index) of restructureProperties(
                            fields.specifications.slice(0, -1)
                          )"
                          :key="index"
                          class="align-middle"
                        >
                          {{ item["property"] }}
                        </th>
                        <th class="align-middle">price</th>
                        <th class="align-middle">quantity</th>
                        <th class="align-middle">weight</th>
                        <th class="align-middle">image</th>
                      </tr>
                    </thead>
                    <tbody
                      v-if="
                        cartesianProduct(
                          restructureProperties(
                            fields.specifications.slice(0, -1)
                          ).map((item) => item.values)
                        )[0].length > 0
                      "
                    >
                      <tr
                        class="align-middle"
                        v-for="(item, index) of cartesianProduct(
                          restructureProperties(
                            fields.specifications.slice(0, -1)
                          ).map((item) => item.values)
                        )"
                        :key="index"
                        :id="index"
                      >
                        <td
                          v-for="(value, ind) of restructureProperties(
                            fields.specifications.slice(0, -1)
                          )"
                          :key="ind"
                          class="align-middle"
                        >
                          {{ Array.isArray(item) ? item[ind] : item }}
                        </td>
                        <td class="align-middle">
                          <input
                            type="number"
                            step="0.01"
                            min="1"
                            class="form-control"
                            v-model="fields.specifications_details[index].price"
                          />
                        </td>
                        <td class="align-middle">
                          <input
                            type="number"
                            step="1"
                            min="0"
                            class="form-control"
                            v-model="
                              fields.specifications_details[index].quantity
                            "
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            step="0.01"
                            min="0"
                            class="form-control"
                            v-model="
                              fields.specifications_details[index].weight
                            "
                          />
                        </td>
                        <td class="align-middle">
                          <input
                            type="file"
                            accept="image/*"
                            class="form-control"
                            @change="uploadImage($event, index)"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </DataTable>
                </div>
                <!-- end tab pane -->
              </div>
              <!-- end tab content -->
            </div>
            <!-- end card body -->
          </div>
          <!-- end card -->
          <div class="text-end mb-3">
            <button type="submit" class="btn btn-success w-sm">
              Enregistrer le produit
            </button>
          </div>
        </form>
      </div>
      <!-- end col -->

      <div class="col-lg-4">
        <div class="card">
          <div class="card-header">
            <h5 class="card-title mb-0">Publier</h5>
          </div>
          <div class="card-body">
            <div class="mb-3">
              <label for="choices-publish-status-input" class="form-label"
                >Statut</label
              >
              <Multiselect
                class="form-control"
                v-model="fields.is_published"
                :close-on-select="true"
                :searchable="true"
                :create-option="true"
                :options="[
                  { value: true, label: 'Publié' },
                  { value: false, label: 'Brouillon' },
                ]"
              />
            </div>
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>

<style scoped>
.drop-zone {
  width: 96%;
  height: 100px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin: 10px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.2);
}
.drop-zone:hover {
  border: 1px solid rgba(0, 0, 0, 0.6);
  color: rgba(0, 0, 0, 0.6);
}
</style>
